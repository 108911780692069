'use client'
import { Button, ChevronLeft } from '@/lib/mui'
import classNames from 'classnames'
import { useRouter } from 'next/navigation'

type BackButtonProps = {
  className?: string
  onClickEvent?: () => boolean
}

export default function BackButton({
  className = '',
  onClickEvent,
}: BackButtonProps) {
  const router = useRouter()
  const onClickHandler = () => {
    if (onClickEvent) {
      const response = onClickEvent()
      if (response) {
        router.back()
      }
    } else {
      router.back()
    }
  }
  return (
    <Button
      type="button"
      variant="switch"
      color="secondary"
      className={classNames('px-0', className)}
      startIcon={<ChevronLeft />}
      onClick={() => onClickHandler()}
      data-cy="back-button"
    >
      Back
    </Button>
  )
}
